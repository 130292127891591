export default {
    ID: {
        label: "Codice Univoco Stanza",
        visible: true
    },
    CD_CDC: {
        label: "Centro di Costo",
        visible: false
    },
    DV_ID: {
        label: "",
        visible: false
    },    
    CDC_NOME: {
        label: "Dipartimento",
        visible: true
    },
    COD_EDIFICIO: {
        label: "",
        visible: false
    },
    NOME_EDIFICIO: {
        label: "",
        visible: false
    },
    IND_EDIFICIO: {
        label: "Indirizzo",
        visible: true
    },
    NUM_PIANO: {
        label: "Piano",
        visible: true
    },
    NOME_PIANO: {
        label: "",
        visible: false
    },
    ORD_PIANO: {
        label: "",
        visible: false
    },
    CODICE_STANZA: {
        label: "Numero Stanza",
        visible: true
    },
    CAT_STANZA: {
        label: "",
        visible: false
    },
    TIPO_STANZA: {
        label: "",
        visible: false
    },
    NOME_STANZA: {
        label: "Tipo stanza",
        visible: true
    },
    AREA_STANZA: {
        label: "",
        visible: false
    },
    CAP: {
        label: "",
        visible: false
    },
    PROV: {
        label: "",
        visible: false
    },
    ZONA: {
        label: "",
        visible: false
    },
    LATITUDINE_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    LONGITUDINE_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    INDI_TIP_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    INDI_STR_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    NUMERO_CIVICO_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    CAP_EDIFICIO_PRINC: {
        label: "",
        visible: false
    },
    LATITUDINE_EDIFICIO_SEC: {
        label: "",
        visible: false
    },
    LONGITUDINE_EDIFICIO_SEC: {
        label: "",
        visible: false
    },
    INDI_TIP_EDIFICIO_SEC: {
        label: "",
        visible: false
    },
    INDI_STR_EDIFICIO_SEC: {
        label: "",
        visible: false
    },  
    NUMERO_CIVICO_EDIFICIO_SEC: {
        label: "",
        visible: false
    },  
    CAP_EDIFICIO_SEC: {
        label: "",
        visible: false
    },
    COD_COMUNE_EDIFICIO: {
        label: "",
        visible: false
    },
    DESC_COMUNE_EDIFICIO: {
        label: "",
        visible: false
    },
    COD_IDENTI_EDIFICIO: {
        label: "",
        visible: false
    },
    POSTI: {
        label: "",
        visible: false
    },
    RM_NAME: {
        label: "",
        visible: false
    },
    SOURCE: {
        label: "",
        visible: false
    },
    RFID_TAG: {
        label: "",
        visible: false
    },
    LOCAL_NAME: {
        label: "Nome locale",
        visible: true
    },
    LOCAL_TELEFONO: {
        label: "Telefono locale",
        visible: true
    },
    LOCAL_EXPOSED: {
        label: "Visibile sui monitor",
        visible: true,
        isflag: true
    },

    // altre tipologie di locali


}