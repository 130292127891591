export default {
    MATRICOLA: {
        label: "Matricola",
        visible: true
    },
    NOME: {
        label: "Nome",
        visible: true
    },
    COGNOME: {
        label: "Cognome",
        visible: true
    },
    TIPO_ORGANICO: {
        label: "",
        visible: false
    },
    DESCRIZIONE_TIPO_ORGANICO: {
        label: "Tipo di organico",
        visible: true
    },
    UO_AFFERENZA: {
        label: "",
        visible: false
    },
    DESC_AFFERENZA: {
        label: "Dipartimento",
        visible: true
    },
    RUOLO: {
        label: "",
        visible: false
    },
    SOURCE: {
        label: "",
        visible: false
    },
    DS_RUOLO: {
        label: "Ruolo",
        visible: true
    },
    EMAIL: {
        label: "Email",
        visible: false
    },
    TELEFONO_UFFICIO: {
        label: "",
        visible: false
    },
    TELEFONO: {
        label: "Telefono interno",
        visible: true
    },
    STATO: {
        label: "Stato",
        visible: true
    },
    LOCAL_COGNOME: {
        label: "Cognome locale",
        visible: true
    },
    LOCAL_NOME: {
        label: "Nome locale",
        visible: true
    },
    LOCAL_TELEFONO: {
        label: "Telefono locale",
        visible: true
    },
    LOCAL_EXPOSED: {
        label: "Visibile sui monitor",
        visible: true,
        isflag: true
    },
    PRIVACY_ASSENSO: {
        label: "Consenso al trattamento",
        visible: true,
        isflag: true
    },
    PRIVACY_INDIRIZZO: {
        label: "Indirizzo abitazione",
        visible: true
    },
    PRIVACY_TELEFONOCASA: {
        label: "Telefono abitazione",
        visible: true
    },
    PRIVACY_TELEFONOMOBILE: {
        label: "Telefono mobile",
        visible: true
    },
}