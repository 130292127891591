<template>
  <v-app>
    <div v-if="this.$store.state.isLoggedIn">
      <application-drawer></application-drawer>
      <top-application-bar></top-application-bar>
      <application-view v-if="$route.path !== '/' "></application-view>
      <home-widget v-else></home-widget>
    </div>

    <div class="text-center ma-4" v-else>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-app>
</template>

<script>
const URL = require('url');
const querystring = require('querystring');

export default {
  name: "App",

  components: {
    TopApplicationBar: () => import("./components/core/AppBar.vue"),
    ApplicationDrawer: () => import("./components/core/AppDrawer.vue"),
    ApplicationView: () => import('./components/core/AppView.vue'),
    HomeWidget: () => import("./views/Home.vue"),
  },

  data: () => ({
    //
  }),

  methods: {
    isJwtValid: function () {
      //console.log(jwt);
      // console.log("Validating jwt...");
      this.$store
        .dispatch("validateJwt")
        .then((status) => {
          if (status === "valid") {
            this.$store.dispatch("loadJwt");
          }
        })
        .catch(() => {
          // console.log(err);
          //console.log("invalid jwt found! Redirecting to cas");
          this.redirectToCas();
        });
    },

    redirectToCas: function () {
      window.location.href = process.env.VUE_APP_CAS_URL_FIRST_PART + encodeURIComponent(window.location.href);
      // console.log('redirecting to ' + process.env.VUE_APP_CAS_URL_FIRST_PART + encodeURIComponent(window.location.href));
      // https://sso.staging.unimi.it:6443/login?service=https%3A%2F%2Fpatrimoniotest.di.unimi.it%2F
    },

    validate: function (ticket, path) {
      //console.log(ticket);

      const headers = {};

      var url =
        process.env.VUE_APP_PATRIMONIO_API + "/common/obtainJwt" + '?ticket=' + ticket + '&path=' + encodeURIComponent(path);

      // We are validating ticket to PATRIMONIO that validate ticket to THOR, if valid we will obtain the patrimonio role of the user that has generated this ticket in a jwt.
      this.$http
        .get(url, { headers })
        .then((response) => {
          //console.log(response);

          if (response.status === 200) {
            // jwt obtained we are now ready to set login true and to store the obtained jwt
            this.$store.dispatch("login", { jwt: response.data });

            // If ticket present into the url redirect to home
            const myURL = URL.parse(window.location.href);
            const myQS = querystring.parse(myURL.query);
            if (myQS.ticket) {
              this.$router.replace(myURL.pathname);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 404) {
            // User not belonging to this dept.
            // TODO: redirect to info/error page
            console.log(err.response.data);
            this.$store.commit('SET_AUTH', { isAuth: true })
            this.$router.push('/usernotfound')
          } else {
            console.log(err);
          }
          
        });
    },
  },

  // As soon as the app is created we check if valid token was found (to avoid login if not necessary)
  // and we try to retrieve data from api
  created: function () {
      
    // console.log("NODE_ENV: " + process.env.NODE_ENV);
    console.log("Patrimonio App Version: " + process.env.VUE_APP_VERSION);

    // Reset if error
    //this.serviceDown = false;

    // Try to get auth token, if empty it automatically try to load it from local storage
    // If empty check if we have stored it on local storage
    // console.log("looking for stored jwt");
    const jwt = localStorage.getItem("patrimonio_auth_token");
    const myURL = URL.parse(window.location.href);
    const myQS = querystring.parse(myURL.query);


    // FTF we are verifing if a jwt is present, if it is then we check for validity
    // If not present then we need to talk with cas for authentication
    // This means that the first time we arrived here without a jwt or with an invalid jwt
    // We were redirected to cas and if login is fine he redirect us here again with a ticket
    // So we should also check if we land here with a ticket into the url
    // If it is present than it means that we were redirected here by cas after a successful login and we should now send the
    // ticket for validation to our backend.
    //console.log('jwt found in memory: ' + jwt);


    if (!(jwt === null)) {
      this.isJwtValid();
    } else {
      // JWT null or invalid, check if in the url there is the cas ticket if not redirect else call backend to validate
      // console.log(this.$route);

      if (myQS.ticket) {
        // probably we were redirected here by CAS, it's now time to call our backend, pass the ticket and wait for validation
        // console.log("Ok pass the ticket to the backend for validation");
        //console.log(this.$route.query.ticket); // outputs 'CAS ticket'

        // HERE WE CALL PATRIMONIO BACKEND that call THOR FOR TICKET VALIDATION
        this.validate(myQS.ticket, myURL.protocol+'//'+myURL.host+myURL.pathname);
      } else {
        // console.log(
        //   "-> null or invalid or Expired token...go to cas to obtain a new ticket"
        // );
        this.redirectToCas();
      }
    }
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.v-toolbar__title {
  color: #003366;
}
</style>
