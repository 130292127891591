export default {
    ID: {
        label: "Numero Inventario",
        visible: true
    },
    CD_INVENT: {
        label: "Centro di costo",
        visible: false
    },
    ANNO_INS_INVE: {
        label: "Anno di inserimento",
        visible: true
    },
    PG_BENE: {
        label: "Codice bene interno",
        visible: false
    },
    PG_BENE_SUB:{
        label: "Codice bene interno correlato",
        visible: false
    },
    PG_BENE_ASSOLUTO:{
        label: "Identificativo bene in Ateneo",
        visible: false
    },
    ID_INVENTARIO_BENI:{
        label: "",
        visible: false
    },
    DS_BENE:{
        label: "Descrizione",
        visible: true
    },
    NUMERO_SERIALE:{
        label: "Numero Seriale",
        visible: true
    },
    MARCA: {
        label: "Produttore",
        visible: true
    },
    DS_CONDIZ_BENE: {
        label: "",
        visible: false
    },
    DT_GARANZIA_DA: {
        label: "Inizio garanzia",
        visible: true
    },
    DT_GARANZIA_A: {
        label: "Fine garanzia",
        visible: true
    },
    CD_FORNITORE: {
        label: "Codice fornitore",
        visible: true
    },
    NOME_ESTESO_FORNITORE: {
        label: "Fornitore",
        visible: true
    },
    VALORE_CONVENZIONALE: {
        label: "Valore",
        visible: true
    },
    CD_CATEG_GRUPPO: {
        label: "Codice categoria del bene",
        visible: false
    },
    DS_CATEG_GRUPPO: {
        label: "Categoria bene",
        visible: true
    },
    CD_IMMOBILIZZAZIONE: {
        label: "",
        visible: false
    },
    DS_IMMOBILIZZAZIONE: {
        label: "",
        visible: false
    },
    CD_POSSESSORE: {
        label: "Matricola assegnatario",
        visible: false
    },
    NOME_ESTESO_POSSESSORE: {
        label: "Assegnatario",
        visible: true
    },
    ID_UO: {
        label: "",
        visible: false
    },
    CD_NODO: {
        label: "",
        visible: false
    },
    CD_CSA: {
        label: "",
        visible: false
    },
    DS_UO: {
        label: "",
        visible: false
    },
    NOME_TIPO_DG: {
        label: "",
        visible: false
    },
    DCE_DT_RIF: {
        label: "",
        visible: false
    },
    DCE_NUM_RIF: {
        label: "",
        visible: false
    },
    DG_DCE_DT_RGS: {
        label: "",
        visible: false
    },
    DG_DCE_NUM_RGS: {
        label: "",
        visible: false
    },
    PG_BUONO_CARICO: {
        label: "Numero buono di carico",
        visible: true
    },
    DT_BUONO_CARICO: {
        label: "Data inserimento buono di carico",
        visible: true
    },
    PG_BUONO_SCARICO: {
        label: "Numero buono di scarico",
        visible: true
    },
    DT_BUONO_SCARICO: {
        label: "Data inserimento buono di scarico",
        visible: true
    },
    ESE_CARICO_BENE_MIGRATO:{
        label: "",
        visible: false
    },
    NUM_BUONO_CARICO_MIGRATO: {
        label: "",
        visible: false
    },
    DT_CAR_BENE_MIGRATO: {
        label: "",
        visible: false
    },
    NOTE:{
        label: "Note",
        visible: true
    },
    // Local data to store owner and position
    CURRENT_OWNER: {
        label: "Assegnatario attuale",
        visible: true
    },
    CURRENT_POSITION: {
        label: "Posizione attuale",
        visible: true
    },
    LAST_SEEN: {
        label: "Data ultimo rinvenimento",
        visible: true
    },
    RFID_TAG: {
        label: "Codice TAG RFID",
        visible: true
    },
    // Technical data
    TECH_TYPE: {
        label: "Tipologia bene",
        visible: true
    },
    TECH_PC_CPU_TYPE:{
        label: "Tipologia CPU",
        visible: true
    },
    TECH_PC_CPU_CLOCK_GHZ:{
        label: "Frequenza CPU",
        visible: true
    },
    TECH_PC_CPU_CORE: {
        label: "Numero core CPU",
        visible: true
    },
    TECH_PC_RAM_GB: {
        label: "Dimensione RAM (GB)",
        visible: true
    },
    TECH_PC_STORAGE_TYPE: {
        label: "Tipologia storage",
        visible: true
    },
    TECH_PC_STORAGE_SIZE_GB: {
        label: "Dimensione storage (GB)",
        visible: true
    },
    TECH_PC_SERIALNUM: {
        label: "Numero seriale",
        visible: true
    },
    TECH_SERVER_CPU_TYPE:{
        label: "Tipologia CPU",
        visible: true
    },
    TECH_SERVER_CPU_CLOCK_GHZ:{
        label: "Frequenza CPU",
        visible: true
    },
    TECH_SERVER_CPU_CORE: {
        label: "Numero core CPU",
        visible: true
    },
    TECH_SERVER_CPU_NUMBER: {
        label: "Numero processori",
        visible: true
    },
    TECH_SERVER_RAM_GB: {
        label: "Dimensione RAM (GB)",
        visible: true
    },
    TECH_SERVER_STORAGE_TYPE: {
        label: "Tipologia storage",
        visible: true
    },
    TECH_SERVER_STORAGE_DISK_NUMBER: {
        label: "Numero dischi",
        visible: true
    },
    TECH_SERVER_STORAGE_SIZE_GB: {
        label: "Dimensione storage (GB)",
        visible: true
    },
    TECH_SERVER_SERIALNUM: {
        label: "Numero seriale",
        visible: true
    },
    TECH_LAPTOP_CPU_TYPE: {
        label: "Tipologia CPU",
        visible: true
    },
    TECH_LAPTOP_CPU_CLOCK_GHZ: {
        label: "Frequenza CPU",
        visible: true
    },
    TECH_LAPTOP_CPU_CORE: {
        label: "Numero core CPU",
        visible: true
    },
    TECH_LAPTOP_RAM_GB: {
        label: "Dimensione RAM (GB)",
        visible: true
    },
    TECH_LAPTOP_STORAGE_TYPE: {
        label: "Tipologia storage",
        visible: true
    },
    TECH_LAPTOP_STORAGE_SIZE_GB: {
        label: "Dimensione storage (GB)",
        visible: true
    },
    TECH_LAPTOP_SERIALNUM:{
        label: "Numero seriale",
        visible: true
    },
    TECH_LAPTOP_SCREENSIZE_INCH:{
        label: "Diagonale monitor (pollici)",
        visible: true
    },
    TECH_TABLET_CPU_TYPE:{
        label: "Tipologia CPU",
        visible: true
    },
    TECH_TABLET_CPU_CLOCK_GHZ: {
        label: "Frequenza CPU",
        visible: true
    },
    TECH_TABLET_CPU_CORE: {
        label: "Numero core CPU",
        visible: true
    },
    TECH_TABLET_STORAGE_SIZE_GB: {
        label: "Dimensione storage (GB)",
        visible: true
    },
    TECH_TABLET_SERIALNUM:{
        label: "Numero seriale",
        visible: true
    },
    TECH_TABLET_SCREENSIZE_INCH: {
        label: "Diagonale monitor (pollici)",
        visible: true
    },
    TECH_TABLET_NETWORK_TYPE:{
        label: "Tipologia network di connessione",
        visible: true
    },
    TECH_MONITOR_SCREENSIZE_INCH:{
        label: "Diagonale monitor (pollici)",
        visible: true
    },
    TECH_MONITOR_SERIALNUM: {
        label: "Numero seriale",
        visible: true
    },
    TECH_MONITOR_PLUGS: {
        label: "Connettori video",
        visible: true
    },
    TECH_MONITOR_AUDIO: {
        label: "Funzionalità audio",
        visible: true
    },
    TECH_MONITOR_RESOLUTION: {
        label: "Risoluzione",
        visible: true
    },
    TECH_PRINTER_TECHNOLOGY: {
        label: "Tipologia stampante",
        visible: true
    },
    TECH_PRINTER_COLOR: {
        label: "BN o Colore",
        visible: true
    },
    TECH_PRINTER_SERIALNUM:{
        label: "Numero seriale",
        visible: true
    },
    AssetTag: {
        label: "Codice etichetta",
        visible: false
    },
    AssetTagId: {
        label: "Codice etichetta",
        visible: false
    },
}