import Vue from 'vue'
import Vuex from 'vuex'

// Use axios as default http client
import axios from 'axios'

// Used to decode the auth jwt received
import jwt_decode from "jwt-decode";

// Import db label mapping for asset
import label_mapping_asset from "../utils/DB_label_mapping_asset"
// Import db label mapping for room
import label_mapping_room from "../utils/DB_label_mapping_room"
// Import db label mapping for user
import label_mapping_user from "../utils/DB_label_mapping_user"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    isLoggedIn: false,
    authJwt: null,
    userInitials: null,
    userRole: null,
    userPrivacy: 'no',
    userMatricola: null,
    labelMappingAsset: label_mapping_asset,
    labelMappingRoom: label_mapping_room,
    labelMappingUser: label_mapping_user
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },

    SET_AUTH(state, payload) {
      state.isLoggedIn = payload.isAuth
    },

    SET_JWT(state, payload) {
      state.authJwt = payload.jwt
    },

    SET_USER_INITIALS(state, payload) {
      state.userInitials = payload.initials
    },

    SET_USER_MATRICOLA(state, payload) {
      state.userMatricola = payload.matricola
    },

    SET_USER_ROLE(state, payload) {
      state.userRole = payload.role
    },
    SET_USER_PRIVACY(state, payload) {
      state.userPrivacy = payload.privacy
    },
  },
  actions: {
    // Action used to login and out (do we have a valid jwt?)
    login(context, payload) {
      // console.log('Logged in correctly!');
      context.commit('SET_AUTH', { isAuth: true });
      context.dispatch('storeJwt', { jwt: payload.jwt })

      // Extract from jwt initials and user role
      // Parse jwt content and fulfill some infoes
      let decoded = jwt_decode(payload.jwt);
      let username = decoded.username;
      let matricola = decoded.matricola;
      let decodedRole = decoded.role;
      let decodedPrivacy = decoded.privacy;
      // Get initials of the user splitting on . and taking the first char of the two elements obtained
      let evaluatedInitials = username.split(".")[0][0] + username.split(".")[1][0];

      // console.log(evaluatedInitials + ' - ' + decodedRole);

      context.commit('SET_USER_MATRICOLA',{matricola: matricola});
      context.commit('SET_USER_INITIALS',{initials: evaluatedInitials});
      context.commit('SET_USER_ROLE', {role: decodedRole});
      context.commit('SET_USER_PRIVACY', {privacy: decodedPrivacy});
    },
    logout(context) {
      // console.log('Logging out!');
      context.commit('SET_AUTH', { isAuth: false });
      context.commit('SET_JWT', { jwt: null });
      // Delete the auth token stored
      localStorage.removeItem("patrimonio_auth_token");
      // console.log('jwt removed from local storage!');
      // Logout cas VUE_APP_CAS_URL_LOGOUT
      window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;

    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    storeJwt(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      localStorage.setItem('patrimonio_auth_token', payload.jwt)
      context.commit('SET_JWT', { jwt: payload.jwt });
    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    loadJwt(context) {
      // Load the jwt from memory if present
      let jwt = localStorage.getItem("patrimonio_auth_token");
      if (!(jwt === null)) {
        context.commit('SET_JWT', { jwt: jwt });
      }
    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    validateJwt(context) {
      return new Promise((resolve, reject) => {
        // Load the jwt from memory if present
        let jwt = localStorage.getItem("patrimonio_auth_token");
        if (!(jwt === null)) {
          // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
          // Prepare the message
          const headers = {
            Authorization: "jwt " + jwt,
          };

          const body = {};

          //console.log(Vue);
          axios.post(process.env.VUE_APP_PATRIMONIO_API + "/common/jwtVerify", body, {
              headers,
            })
            .then((response) => {
              //console.log(response);
              if (response.status === 200) {
                if (response.data.status === "valid") {
                  // console.log("-> Valid token found -> autologin");
                  // Valid token found, we reload the jwt user info passing from the common onValidate method
                  context.dispatch('login', { jwt: jwt });
                  resolve("valid");
                }
              } else if (response.status === 404) {
                // console.log("The service is down. Please try again later...");
                //this.serviceDown = true;
                reject("servicedown");
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response.data);
              } else {
                console.log(err);
              }
              context.dispatch('logout');
              // console.log("JWT verification error...redirectin to CAS for new authentication!");
              reject("notvalid");
            });
        }
      })
    },
    // Help On Line
    help() {
      window.open('/help/index.html');
    },

    // // Obtain nice asset label name starting from db column asset name
    // getNiceLabel(context, payload){

    //   // Payload = nome colonna db
    //   return context.getters(labelMapping)[payload]
    // }
  },
  modules: {
  }
})
